<!-- TODO: update modal design -->
@if (!newModalStyle) {
  <div>
    <div [ngClass]="data?.customModalClass ? data.customModalClass : ''">
      @if (showClose) {
        <div class="close-button-container" align="right">
          <mat-icon class="close-button mat-icon" aria-hidden="true" data-mat-icon-type="font" (click)="close()"
            >close</mat-icon
          >
        </div>
      }
      @if (title) {
        <div class="title-section">
          <h2 mat-dialog-title [ngStyle]="{ 'text-align': alignTitle }">{{ title }}</h2>
        </div>
      }
      <div tabindex="0" class="template-container">
        <i class="modal-icon"></i>
        <div class="modal-template">
          <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
        </div>
      </div>
      @if (showActions) {
        <div mat-dialog-actions>
          <button class="btn-action btn-deny" data-testid="close-button" mat-button (click)="close()">Cancel</button>
          @if (showDone) {
            <button
              id="btnConfirm"
              class="btn-action btn-confirm"
              data-testid="confirm-button"
              mat-button
              (click)="onClick()"
              [disabled]="isButtonDisabled()"
              [ngStyle]="{ color: data.actionButtonColor || '#333' }"
            >
              {{ actionButtonTitle }}
            </button>
          }
        </div>
      }
    </div>
  </div>
}

@if (newModalStyle) {
  <div>
    <div class="modal-component" [ngClass]="data?.customModalClass ? data.customModalClass : ''">
      <div class="title-wrap" [ngClass]="titleIcon ? 'title-wrap-center' : ''">
        <div class="title-text-wrap">
          @if (titleIcon) {
            <div>
              <mat-icon class="text-icon-title" svgIcon="{{ titleIcon }}"></mat-icon>
            </div>
          }
          @if (title) {
            <p [ngClass]="titleIcon ? 'center-title' : ''">{{ title }}</p>
          }
        </div>
        @if (showClose) {
          <div class="new-close-button-container" align="right">
            <mat-icon class="new-close-button mat-icon" aria-hidden="true" data-mat-icon-type="font" (click)="close()"
              >close</mat-icon
            >
          </div>
        }
      </div>
      <div tabindex="0" class="social-modal-container template-container">
        <i class="modal-icon"></i>
        <div class="modal-template" [ngStyle]="titleIcon ? { 'text-align': 'center' } : {}">
          <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
        </div>
      </div>
      @if (showActions) {
        <div class="social-action-modal-footer">
          <div>
            @if (showTertiaryButton) {
              <a
                href="{{ tertiaryLink }}"
                data-testid="help-button"
                target="_blank"
                rel="noopener"
                class="custom-link-button m-t-16 text-color-secondary"
              >
                {{ tertiaryButtonTitle }}
              </a>
            }
          </div>
          <div>
            @if (showDone) {
              <button
                id="btnConfirm"
                class="primary-button"
                data-testid="confirm-button"
                (click)="onClick()"
                [disabled]="isButtonDisabled()"
                [ngStyle]="{ color: data.actionButtonColor || 'white' }"
              >
                {{ actionButtonTitle }}
              </button>
            }
            @if (showSecondaryButton) {
              <button class="secondary-button" data-testid="close-button" (click)="close()">
                {{ secondaryButtonTitle }}
              </button>
            }
          </div>
        </div>
      }
    </div>
  </div>
}
