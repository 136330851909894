import { Component, HostBinding, Inject, OnInit } from '@angular/core';

import { SnackBarMode, SnackBarType } from 'app/shared/snackbar/snackbar.component';

import { ModalEventService } from '../../modal-event.service';
import { CONTAINER_DATA } from '../../modal.service';

export interface SimpleMessageData {
  message: string;
}

@Component({
  selector: 'app-simple-message',
  templateUrl: './simple-message.component.html',
  styleUrls: ['./simple-message.component.scss'],
  standalone: false,
})
export class SimpleMessageComponent implements OnInit {
  public bodyMessage: string;
  protected message = 'This is a message';
  protected type: SnackBarType = 'info';
  protected icon = 'INFO_OUTLINE';
  protected isMatIcon = false;
  protected mode: SnackBarMode = 'light';

  @HostBinding('class.info')
  protected get whatType(): boolean {
    return this.type === 'info';
  }

  @HostBinding('class.success')
  protected get whatTypeSuccess(): boolean {
    return this.type === 'success';
  }

  @HostBinding('class.error')
  protected get whatTypeError(): boolean {
    return this.type === 'error';
  }

  public constructor(
    @Inject(CONTAINER_DATA) public data: SimpleMessageData,
    private eventService: ModalEventService,
  ) {
    this.bodyMessage = data.message;
  }

  public ngOnInit(): void {
    this.eventService.peek().next(true);
  }
}
